import { create } from 'zustand';
import {useEffect} from 'react';
import IItem from 'components/page/search/map/interfaces/IItem';
import IFilter from 'components/page/search/map/interfaces/IFilter';
import useNotification from 'modules/notification/useNotification';
import {useDebouncedCallback} from 'use-debounce';
import useLoading from 'modules/stores/loading/useLoading';
import useFilter from 'modules/stores/page/search/useFilter';
import useIsFetching from 'modules/stores/page/search/useIsFetching';
import useItems from 'modules/stores/page/search/useItems';
import {
    DEFAULT_LATITUDE,
    DEFAULT_LONGITUDE,
    DEFAULT_ZOOM,
    IData
} from 'modules/stores/page/search/useMap';
import {devProjectsSearchMapApi} from 'modules/api/client';
import useSelectedItemId from 'modules/stores/page/search/useSelectedItemId';

export const PAGE_SIZE = 20;

type SearchPageStore = {
    filterState: IFilter;
    setFilterState: (filter: IFilter) => void;

    isFetchingState: boolean;
    setIsFetchingState: (flag: boolean) => void;

    itemsState: IItem[];
    setItemsState: (items: IItem[]) => void;

    hoveredItemIdState: string;
    setHoveredItemIdState: (itemId: string) => void;

    selectedItemIdState: string;
    setSelectedItemIdState: (itemId: string) => void;

    mapDataState: IData;
    setMapDataState: (data: IData) => void;

    mapShouldResetState: boolean;
    setMapShouldResetState: (flag: boolean) => void;
};

export const useSearchPageStore = create<SearchPageStore>((set) => ({
    filterState: null,
    setFilterState: (filter) => set(() => ({ filterState: filter })),

    isFetchingState: true,
    setIsFetchingState: (flag) => set(() => ({ isFetchingState: flag })),

    itemsState: [],
    setItemsState: (items) => set(() => ({ itemsState: items })),

    hoveredItemIdState: '',
    setHoveredItemIdState: (id) => set(() => ({ hoveredItemIdState: id })),

    selectedItemIdState: '',
    setSelectedItemIdState: (id) => set(() => ({ selectedItemIdState: id })),

    mapDataState: {
        zoom: DEFAULT_ZOOM,
        latitude: DEFAULT_LATITUDE,
        longitude: DEFAULT_LONGITUDE
    },
    setMapDataState: (data) => set(() => ({ mapDataState: data })),

    mapShouldResetState: false,
    setMapShouldResetState: (flag) => set(() => ({ mapShouldResetState: flag })),
}));

export const useState = (): void => {
    const { start: startLoading, stop: stopLoading } = useLoading();
    const { error: showErrorNotification } = useNotification();

    const { filter } = useFilter();
    const { setIsFetching } = useIsFetching();
    const { setItems } = useItems();
    const { selectedItemId, setSelectedItemId } = useSelectedItemId();

    const fetchItems = async () => {
        startLoading();
        setIsFetching(true);

        try {
            const apiFilter = {...filter};
            delete apiFilter.locationId;
            delete apiFilter.viewport;
            const offers = (await devProjectsSearchMapApi.getProjects(apiFilter))?.data || [];
            setItems(offers);
            !offers.some( offer => offer.id === selectedItemId) && setSelectedItemId('');
        } catch (error) {
            setItems([]);
            showErrorNotification('Nepodarilo sa načítať inzeráty');
        } finally {
            setIsFetching(false);
            stopLoading();
        }
    };

    const fetchItemsDebounced = useDebouncedCallback(fetchItems, 1000);

    const filterForWatch = {...filter};
    delete filterForWatch.viewport;
    const filterForWatchSerialized = JSON.stringify(filterForWatch);

    useEffect(() => {
        fetchItemsDebounced();
    }, [filterForWatchSerialized, fetchItemsDebounced]);
};