import React, {FC} from 'react';
import {Box, Grid} from '@mui/material';
import Link from 'components/link/Link';
import IItem from 'components/page/search/map/interfaces/IItem';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import useHoveredItemId from 'modules/stores/page/search/useHoveredItemId';
import {Photo} from 'components/page/search/map/components/searchResult/advertisement/photo/Photo';
import {Title} from 'components/page/search/map/components/searchResult/advertisement/title/Title';
import {Labels} from 'components/page/search/map/components/searchResult/advertisement/labels/Labels';
import {PriceInfo} from 'components/page/search/map/components/searchResult/advertisement/priceInfo/PriceInfo';
import {SummaryInfo} from 'components/page/search/map/components/searchResult/advertisement/summaryInfo/SummaryInfo';
import {LocationInfo} from 'components/page/search/map/components/searchResult/advertisement/locationInfo/LocationInfo';
import {
    GradientWrapper
} from 'components/page/search/map/components/searchResult/advertisement/gradientWrapper/GradientWrapper';
import { IGtmEvent } from 'modules/analytics/IAnalytics';

interface IProps {
    item: IItem;
    isMapItem?: boolean;
    isHoverable?: boolean;
    gtmEventData: IGtmEvent;
}

export const Advertisement: FC<IProps> = ({item, isHoverable = false, gtmEventData, isMapItem}) => {
    const {setHoveredItemId} = useHoveredItemId();
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();
    const {id, prices, url, location, photos, services: {isPremium, isTop}, title, categoryName, unitCounts} = item;

    const textInfoColumns = isMapItem ? 12 : isPremium ? 6 : 12;
    const priceInfoColumns = isMapItem ? 12 : isPremium ? 6 : 12;


    return <Link href={url} openInNewTab onClick={() => {
        gtmSendEvent(gtmEventData);
    }}>
        <Box
            borderRadius={2}
            overflow="hidden"
            position="relative"
            height={{ xs: isMapItem ? 255 : isPremium ? 480 : 320, sm: isMapItem ? 320 : isPremium ? 250 : 320 }}
        >
            <Box position="absolute" zIndex={1}>
                <Labels isTop={isTop} isPremium={isPremium} numberOfPhotos={photos.length}/>
            </Box>

            <Photo src={photos[0]}/>

            <GradientWrapper flexDirection={isPremium ? 'row' : 'column'}>

                <Grid container rowSpacing={.8}>
                    <Grid item xs={12}>
                        <Title
                            title={title}
                            onMouseOver={() => isHoverable && setHoveredItemId(id)}
                            onMouseOut={() => isHoverable && setHoveredItemId('')}
                        />
                    </Grid>

                    <Grid item xs={12} gap={.2} container alignItems="center" justifyContent="end" sm={textInfoColumns}>
                        <Grid item xs={12}>
                            <LocationInfo
                                city={location.city}
                                street={location.street}
                                district={location.district}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SummaryInfo
                                categoryName={categoryName}
                                advertisementCount={unitCounts.filtered}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={priceInfoColumns}>
                        <PriceInfo
                            isMapItem={isMapItem}
                            isPremium={isPremium}
                            unitFrom={prices.unit.from}
                            priceOption={prices.options}
                            priceFrom={prices.total.from}
                            unitUnitId={prices.unit.unitId}
                            priceUnitId={prices.total.unitId}
                        />
                    </Grid>
                </Grid>

            </GradientWrapper>
        </Box>
    </Link>;
};
