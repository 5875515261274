import { useSearchPageStore } from 'modules/stores/page/search/useSearchPageStore';

interface IReturn {
    selectedItemId: string;
    setSelectedItemId: (selectedItemId: string) => void;
}

const useSelectedItemId = (): IReturn => {
    const selectedItemId = useSearchPageStore((store) => store.selectedItemIdState);
    const setSelectedItemId = useSearchPageStore((store) => store.setSelectedItemIdState);

    return {
        selectedItemId,
        setSelectedItemId
    };
};

export default useSelectedItemId;
