import { useSearchPageStore } from 'modules/stores/page/search/useSearchPageStore';

interface IReturn {
    isFetching: boolean;
    setIsFetching: (isFetching: boolean) => void;
}

const useIsFetching = (): IReturn => {
    const isFetching = useSearchPageStore((store) => store.isFetchingState);
    const setIsFetching = useSearchPageStore((store) => store.setIsFetchingState);

    return {
        isFetching,
        setIsFetching
    };
};

export default useIsFetching;
