import IFilter from 'components/page/search/map/interfaces/IFilter';
import { useSearchPageStore } from 'modules/stores/page/search/useSearchPageStore';

interface IReturn {
    filter: IFilter;
    setFilter: (filter: IFilter) => void;
    isFilterEmpty: boolean;
}

const useFilter = (): IReturn => {
    const filter = useSearchPageStore((store) => store.filterState);
    const setFilter = useSearchPageStore((store) => store.setFilterState);
    const isFilterEmpty = (
        filter?.locationId
        || filter?.fulltext
        || filter?.transaction
        || filter?.category
        || filter?.state
        || filter?.price?.from
        || filter?.price?.to
    ) === undefined && filter?.allProjects === true;

    return {
        filter,
        setFilter,
        isFilterEmpty,
    };
};

export default useFilter;
