import { useSearchPageStore } from 'modules/stores/page/search/useSearchPageStore';

interface IReturn {
    hoveredItemId: string;
    setHoveredItemId: (hoveredItemId: string) => void;
}

const useHoveredItemId = (): IReturn => {
    const hoveredItemId = useSearchPageStore((store) => store.hoveredItemIdState);
    const setHoveredItemId = useSearchPageStore((store) => store.setHoveredItemIdState);

    return {
        hoveredItemId,
        setHoveredItemId
    };
};

export default useHoveredItemId;
