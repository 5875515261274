import IItem from 'components/page/search/map/interfaces/IItem';
import { useSearchPageStore } from 'modules/stores/page/search/useSearchPageStore';

interface IReturn {
    items: IItem[];
    setItems: (items: IItem[]) => void;
}

const useItems = (): IReturn => {
    const items = useSearchPageStore((store) => store.itemsState);
    const setItems = useSearchPageStore((store) => store.setItemsState);

    return {
        items,
        setItems
    };
};

export default useItems;
