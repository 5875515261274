import { FC } from 'react';
import { Grid } from '@mui/material';
import { ArrowButton } from 'modules/theme/components/paginator/partials/arrowButtons/ArrowButton';

interface IArrowButtons {
    nextHref: string;
    prevHref: string;
    currentPage: number;
    onClick?: () => void;
    isDisabled?: boolean;
    totalPageCount: number;
    onChange?: (newPage: number) => void;
}

export const ArrowButtons: FC<IArrowButtons> = ({
    onClick,
    nextHref,
    prevHref,
    onChange,
    isDisabled,
    currentPage,
    totalPageCount,
}) => {
    const showPrewBtn = currentPage !== 1;
    const showNextBtn = currentPage !== totalPageCount;

    return (
        <Grid
            container
            spacing={1}
            display="flex"
            mb={{ xs: 2, md: 0 }}
            justifyContent="center"
        >
            {showPrewBtn && (
                <Grid item xs={5}>
                    <ArrowButton
                        href={prevHref}
                        direction="back"
                        onClick={onClick}
                        onChange={onChange}
                        testId="showPrewBtn"
                        isDisabled={isDisabled}
                        currentPage={currentPage}
                    />
                </Grid>
            )}
            {showNextBtn && (
                <Grid item xs={5}>
                    <ArrowButton
                        href={nextHref}
                        onClick={onClick}
                        direction="forward"
                        onChange={onChange}
                        testId="showNextBtn"
                        isDisabled={isDisabled}
                        currentPage={currentPage}
                    />
                </Grid>
            )}
        </Grid>
    );
};
